<template>
  <div class="d-inline-flex justify-center align-center">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-img
          :id="`nameColumnIcon_${tableItem.connectionStatus}`"
          :src="`/img/${tableItem.connectionStatus}.svg`"
          class="mr-3 mt-2"
          v-bind="attrs"
          max-height="40px"
          max-width="40px"
          v-on="on"
        />
      </template>
      <span>{{ $t(`nodes.list.${tableItem.connectionStatus}`) }}</span>
    </v-tooltip>

    <div class="mt-2">
      {{ tableItem.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
